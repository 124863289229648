import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Task } from '../task';
import { Global } from '../global';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  public tasks: Task[] = [];
  public taskFilter = '';
  public selectedTasks: Task[] = [];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private global: Global) {
    http.get<Task[]>(this.baseUrl + 'api/Tasks/GetTasks').subscribe(result => {
      this.tasks = result;
      this.tasks.map(function (item) {
        switch (item.status) {
          case -2: item.statusName = 'Ошибка'; break;
          case -1: item.statusName = 'Отмена'; break;
          case 0: item.statusName = 'Не начат'; break;
          case 1: item.statusName = 'Выполняется'; break;
          case 2: item.statusName = 'Выполнен'; break;
        }
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {
    this.taskFilter = this.global.linkFilter;
  }

  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  toggleActive(item: Task) {
    this.toggle(this.selectedTasks, item);
    item.isSelected = !item.isSelected;
  }

  stopTask(task: Task) {
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/StopTask', task.id).subscribe(result => {
      this.tasks = result;
      this.tasks.map(function (item) {
        switch (item.status) {
          case -2: item.statusName = 'Ошибка'; break;
          case -1: item.statusName = 'Отмена'; break;
          case 0: item.statusName = 'Не начат'; break;
          case 1: item.statusName = 'Выполняется'; break;
          case 2: item.statusName = 'Выполнен'; break;
        }
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
  }

  stopAllTasks() {
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/StopAllTasks', {}).subscribe(result => {
      this.tasks = result;
      this.tasks.map(function (item) {
        switch (item.status) {
          case -2: item.statusName = 'Ошибка'; break;
          case -1: item.statusName = 'Отмена'; break;
          case 0: item.statusName = 'Не начат'; break;
          case 1: item.statusName = 'Выполняется'; break;
          case 2: item.statusName = 'Выполнен'; break;
        }
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
  }

  refreshTasks() {
    this.http.get<Task[]>(this.baseUrl + 'api/Tasks/GetTasks').subscribe(result => {
      this.tasks = result;
      this.tasks.map(function (item) {
        switch (item.status) {
          case -2: item.statusName = 'Ошибка'; break;
          case -1: item.statusName = 'Отмена'; break;
          case 0: item.statusName = 'Не начат'; break;
          case 1: item.statusName = 'Выполняется'; break;
          case 2: item.statusName = 'Выполнен'; break;
        }
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
  }
}
