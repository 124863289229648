import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { Global } from "../global";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  public authModel: AuthorizationModel = {
    login1: '',
    password: ''
  };
  public errorMessage: string = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private router: Router, private global: Global) {
  }

  ngOnInit() {
  }

  public Authorize(): any {
    const body = {
      login1: this.authModel.login1,
      password: this.authModel.password
    }
    this.http.post<AuthorizationResult>(this.baseUrl + 'api/Auth/Login', body).subscribe(result => {
      if (result.status != 0) {
        if (result.status == -1) {
          this.errorMessage = "Данные пользователя введены неверно";
        }
        else if (result.status == 1) {
          this.errorMessage = "Пользователь заблокирован";
        }
        else if (result.status == 2) {
          this.errorMessage = "Пользователь удален";
        }
        else {
          this.errorMessage = "Ошибка авторизации";
        }
      }
      else {
        debugger;
        localStorage.setItem("role", result.userRole.toString());
        this.global.userRole = result.userRole;
        this.router.navigate(['/cards']);
      }
    }, error => {
      this.errorMessage = "Ошибка авторизации";
      console.error(error);
    });
  }
}

interface AuthorizationModel {
  login1: string;
  password: string;
}

interface AuthorizationResult {
  status: number;
  userRole: number;
}
