export class Device {
  id: number;
  imei: string;
  ipsPass: string;
  bootPass: string;
  name: string;
  description: string;
  lastPoint: Date;
  rfaddr: string;
  status: number;
  isActive = false;
  activeStatus = 0;
  moduleId = 0;
  isSelected = false;
  isLive = false;
}
