import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { CardsComponent } from './cards/cards.component';
import { TasksComponent } from './tasks/tasks.component';
import { SettingsComponent } from './settings/settings.component';
import { SearchPipePipe } from './search-pipe.pipe';
import { UsersComponent } from './users/users.component';
import { AuthComponent } from './auth/auth.component';

import { AuthService } from './auth.service';
import { DeviceFilterPipe } from './device-filter.pipe';
import { KeyFilterPipe } from './key-filter.pipe';

import { Global } from './global';
import { UtcPipe } from './utc.pipe';
import { SortPipe } from './sort.pipe';
import { UniquePipe } from './pipes/unique.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    CardsComponent,
    TasksComponent,
    SettingsComponent,
    SearchPipePipe,
    UsersComponent,
    AuthComponent,
    DeviceFilterPipe,
    KeyFilterPipe,
    UtcPipe,
    SortPipe,
    UniquePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    DlDateTimePickerDateModule,
    NgbModule,
    OrderModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'cards', component: CardsComponent },
      { path: 'tasks', component: TasksComponent },
      { path: 'users', component: UsersComponent },
      { path: 'login', component: AuthComponent },
    ])
  ],
  providers: [AuthService, Global],
  bootstrap: [AppComponent]
})
export class AppModule { }
