import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { sha256, sha224 } from 'js-sha256';
import { Key } from '../key';
import { Device } from '../device';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public logins: Login[] = [];
  public keys: Key[] = [];
  public devices: Device[] = [];

  public toggleDevicesMode = false;
  public toggleKeysMode = false;

  public passUpdated: false;

  public loginsFilter = '';
  public devicesFilter = '';
  public keysFilter = '';

  public itemsPerPageUsers = 15;
  public itemsPerPageDevices = 15;
  public itemsPerPageKeys = 15;

  public selectedLogins: Login[] = [];
  public activatedLogin: Login = new Login();

  public initialAccess: LoginAccess;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    http.get<Login[]>(this.baseUrl + 'api/Login/GetLogins').subscribe(result => {
      this.logins = result;
      this.logins.map(function (item) {
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
    http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result;
    }, error => {
      console.log(error);
    });
    http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result;
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {
  }

  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  toggleAll(type) {
    const self = this;
    switch (type) {
      case 'key': this.toggleKeysMode = !this.toggleKeysMode;
        this.keys.map(function (item) { item.isSelected = self.toggleKeysMode; });
        break;
      case 'device': this.toggleDevicesMode = !this.toggleDevicesMode;
        this.devices.map(function (item) { item.isSelected = self.toggleDevicesMode; });
        break;
    }
  }

  toggleSelected(item: Login, refresh: boolean) {
    if (this.activatedLogin !== item || refresh) {
      this.activatedLogin = item;
      this.http.post<LoginAccess>(this.baseUrl + 'api/Access/GetLoginAccesses', this.activatedLogin.id).subscribe(result => {
        this.keys.map(function (item) {
          item.isSelected = result.keyAccess.includes(item.id) ? true : false;
        });
        this.devices.map(function (item) {
          item.isSelected = result.deviceAccess.includes(item.id) ? true : false;
        });
        this.initialAccess = result;
      });
    }
  }

  updateAccesses() {
    const self = this;
    const newDeviceAccesses = [];
    this.devices.map(function (item) {
      if (self.initialAccess.deviceAccess.includes(item.id) && item.isSelected === false) {
        newDeviceAccesses.push(new LoginDeviceAccess(self.initialAccess.loginID, item.id, 0));
      }
      if (!self.initialAccess.deviceAccess.includes(item.id) && item.isSelected === true) {
        newDeviceAccesses.push(new LoginDeviceAccess(self.initialAccess.loginID, item.id, 1));
      }
    });
    this.http.post<boolean>(this.baseUrl + 'api/Access/UpdateDeviceAccess', newDeviceAccesses).subscribe(result => {
      const newKeyAccesses = [];
      this.keys.map(function (item) {
        if (self.initialAccess.keyAccess.includes(item.id) && item.isSelected === false) {
          newKeyAccesses.push(new LoginKeyAccess(self.initialAccess.loginID, item.id, 0));
        }
        if (!self.initialAccess.keyAccess.includes(item.id) && item.isSelected === true) {
          newKeyAccesses.push(new LoginKeyAccess(self.initialAccess.loginID, item.id, 1));
        }
      });
      this.http.post<boolean>(this.baseUrl + 'api/Access/UpdateKeyAccess', newKeyAccesses).subscribe(result => {
        document.getElementById('openAccessUpdateModal').click();
        self.toggleSelected(self.activatedLogin, true);
      });
    });
  }

  openCreateUserModal() {
    this.activatedLogin = new Login();
  }

  addLogin() {
    console.log(this.activatedLogin);
    return this.http.post<Login[]>(this.baseUrl + 'api/Login/LoginCreate', this.activatedLogin).subscribe(result => {
      console.log(result);
      this.logins = result;
    });
  }

  updateLogin() {
    console.log(this.activatedLogin);
    if (this.activatedLogin.newPass && this.activatedLogin.newPass != '') {
      const hash = sha256.create();
      hash.update(this.activatedLogin.newPass);
      hash.hex();
      this.activatedLogin.pass = hash.toString().toUpperCase();
    }
    return this.http.post<Login[]>(this.baseUrl + 'api/Login/LoginUpdate', this.activatedLogin).subscribe(result => {
      console.log(result);
      this.logins = result;
    });
  }
}

class Login {
  public id: number;
  public name: string;
  public login1: string;
  public pass: string;
  public newPass: string;
  public role: number;
  public status: number;
  public isSelected = false;
}
class LoginAccess {
  loginID: number;
  deviceAccess: number[];
  keyAccess: number[];
}
class LoginDeviceAccess {
  LoginID: number;
  DeviceID: number;
  Access: number;
  constructor(loginId: number, deviceId: number, access: number) {
    this.LoginID = loginId;
    this.DeviceID = deviceId;
    this.Access = access;
  }
}
class LoginKeyAccess {
  LoginID: number;
  KeyId: number;
  Access: number;
  constructor(loginId: number, keyId: number, access: number) {
    this.LoginID = loginId;
    this.KeyId = keyId;
    this.Access = access;
  }
}

