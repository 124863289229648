import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    debugger;
    let uniqueArray = [];
    value.map(x => {
      if (!uniqueArray.includes(uniqueArray.find(y => y[args] === x[args]))) {
        uniqueArray.push(x);
      }
    });

    return uniqueArray;
  }

}
