import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Task } from '../task';
import { Key } from '../key';
import { Device } from '../device';
import { Global } from '../global';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  public cardGroupsShowed = true;
  public trekerGroupsShowed = true;
  public autoExtNum = false;
  public autoIntNum = false;
  public defaultIpsPass = '';
  public defaultBootPass = '';
  public defaultRfAddr = '';

  public searchKey = '';
  public searchDevice = '';
  public searchKeyGroup = '';
  public searchDeviceGroup = '';
  public taskSearchFilter = '';

  // initial arrays
  public keys: Key[] = [];
  public keyGroups: GroupKey[] = [];
  public devices: Device[] = [];
  public deviceGroups: GroupDevice[] = [];

  public selectedEntityType: string;
  public activatedEntity: any = { id: 0, type: '', data: {} };
  public activeKey: Key = new Key();
  public activeDevice: Device = new Device();
  public activeKeyGroup: GroupKey = new GroupKey();
  public activeDeviceGroup: GroupDevice = new GroupDevice();
  public activeModule: Module = new Module();

  public toggleKeysMode = false;
  public toggleKeyGroupsMode = false;
  public toggleDevicesMode = false;
  public toggleDeviceGroupsMode = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, public global: Global,
              private router: Router, private orderPipe: OrderPipe) {
    http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    http.get<GroupKey[]>(this.baseUrl + 'api/GroupKey/GetGroupKey').subscribe(result => {
      this.keyGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result.map(function (item) { item.isSelected = false; return item; });
      this.devices = this.devices.map(function (item) {
        const date = new Date();
        if (item.lastPoint) {
          const lastDate = new Date(item.lastPoint);
          const diff = Math.abs(lastDate.getTime() - date.getTime());
          if (diff < 600000 + 7200000) {
            item.isLive = true;
          }
        }
        return item;
      });
    }, error => {
      console.log(error);
    });
    http.get<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/GetGroupDev').subscribe(result => {
      this.deviceGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {

  }

  goByLink(linkText) {
    this.global.linkFilter = linkText;
    this.router.navigate(['/tasks']);
  }

  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  createTasks(type) {
    const tasks = [];
    const keys = this.keys.filter(x => x.isSelected === true);
    const devices = this.devices.filter(x => x.isSelected === true);
    keys.map(function (key) {
      devices.map(function (device) {
        const task: Task = {} as Task;
        task.keyID = key.id;
        task.moduleID = device.moduleId;
        task.type = type;
        tasks.push(task);
      });
    });
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/CreateTasks', tasks).subscribe(result => {
      const self = this;
    });
  }

  removeCardsTaskCreate() {
    debugger;
    const tasks = [];
    const devices = this.devices.filter(x => x.isSelected === true);
    devices.map(function (device) {
      const task: Task = {} as Task;
      task.keyID = null;
      task.moduleID = device.moduleId;
      task.type = 0; // for delete
      tasks.push(task);
    });
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/CreateTasks', tasks).subscribe(result => {
      const self = this;
    });
  }

  toggleSelected(type) {
    const self = this;
    switch (type) {
      case 'key': this.toggleKeysMode = !this.toggleKeysMode;
        this.keys.map(function (item) { item.isSelected = self.toggleKeysMode; });
        break;
      case 'device': this.toggleDevicesMode = !this.toggleDevicesMode;
        this.devices.map(function (item) { item.isSelected = self.toggleDevicesMode; });
        break;
      case 'keyGroup':
        this.toggleKeyGroupsMode = !this.toggleKeyGroupsMode;
        this.keyGroups.map(function (item) {
          item.isSelected = self.toggleKeyGroupsMode;
          if (item.isSelected === true) {
            self.selectGroup('keyGroup', item);
          }
        });
        break;
      case 'deviceGroup':
        this.toggleDeviceGroupsMode = !this.toggleDeviceGroupsMode;
        this.deviceGroups.map(function (item) {
          item.isSelected = self.toggleDeviceGroupsMode;
          if (item.isSelected === true) {
            self.selectGroup('deviceGroup', item);
          }
        });
        break;
    }
  }

  selectGroup(type, entity) {
    let apiUrl = '';
    if (!entity.isSelected) {
      return;
    }
    switch (type) {
      case 'keyGroup': apiUrl = 'GetLinksForKeyGroup'; break;
      case 'deviceGroup': apiUrl = 'GetLinksForDeviceGroup'; break;
    }
    return this.http.post<Link>(this.baseUrl + 'api/Links/' + apiUrl, entity.id).subscribe(result => {
      const self = this;
      result.keyIds.map(function (item) {
        const res = self.keys.find(x => x.id === item.id);
        if (res) {
          res.isSelected = true;
        }
      });
      result.deviceIds.map(function (item) {
        const res = self.devices.find(x => x.id === item.id);
        if (res) {
          res.isSelected = true;
        }
      });
    });
  }

  hexToDec(value) {
    return parseInt(value.toString(), 16);
  }

  decToHexPad(value) {
    return (+value ? +value : 0).toString(16).padStart(10, '0').toUpperCase();
  }

  setExtNum() {
    if (this.autoExtNum === true) {
      this.activeKey.externalNum = this.hexToDec(this.activeKey.internalNum) ? this.hexToDec(this.activeKey.internalNum).toString() : '';
    }
  }

  setIntNum() {
    if (this.autoIntNum) {
      this.activeKey.internalNum =
      this.decToHexPad(this.activeKey.externalNum) ? this.decToHexPad(this.activeKey.externalNum).toString() : '';
    }
  }

  toggleActive(type, entity) {
    this.keys.map(function (item) {
      item.isActive = false;
      item.activeStatus = 0;
    });
    this.devices.map(function (item) {
      item.isActive = false;
      item.activeStatus = 0;
    });
    this.keyGroups.map(item => item.isActive = false);
    this.deviceGroups.map(item => item.isActive = false);
    if (this.activatedEntity.id === entity.id && this.activatedEntity.type === type) {
      this.activatedEntity = { id: 0, type: '', data: null };
      return;
    }
    let apiUrl = '';
    this.activatedEntity.id = entity.id;
    this.activatedEntity.type = type;
    this.activatedEntity.data = entity;
    switch (type) {
      case 'key': apiUrl = 'GetLinksForKey'; break;
      case 'device': apiUrl = 'GetLinksForModule'; break;
      case 'keyGroup': apiUrl = 'GetLinksForKeyGroup'; break;
      case 'deviceGroup': apiUrl = 'GetLinksForDeviceGroup'; break;
    }
    return this.http.post<Link>(this.baseUrl + 'api/Links/' + apiUrl, type === 'device' ? entity.moduleId : entity.id).subscribe(result => {
      const self = this;
      result.keyIds.map(function (item) {
        const res = self.keys.find(x => x.id === item.id);
        if (res) {
          res.isActive = true;
          res.activeStatus = item.status;
        }
      });
      result.deviceIds.map(function (item) {
        const res = self.devices.find(x => x.id === item.id);
        if (res) {
          res.isActive = true;
          res.activeStatus = item.status;
        }
      });
      result.deviceGroupIds.map(function (item) {
        const res = self.deviceGroups.find(x => x.id === item);
        if (res) { res.isActive = true; }
      });
      result.keyGroupIds.map(function (item) {
        const res = self.keyGroups.find(x => x.id === item);
        if (res) { res.isActive = true; }
      });
      if (type === 'keyGroup' || type === 'device') {
        this.keys = this.orderPipe.transform(this.keys, ['isActive', 'activeStatus'], true);
      }
      if (type === 'deviceGroup' || type === 'key') {
        this.devices = this.orderPipe.transform(this.devices, ['isActive', 'activeStatus'], true);
      }
    });
  }

  createEntity() {
    if (this.selectedEntityType === 'key') {
      return this.http.post<Key[]>(this.baseUrl + 'api/Keys/AddKey', this.activeKey).subscribe(result => {
        console.log(result);
        this.keys = result;
        this.activeKey = new Key();
      });
    }
    if (this.selectedEntityType === 'device') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddDevice', this.activeDevice).subscribe(result => {
        console.log(result);
        this.devices = result;
        this.devices = this.devices.map(function (item) {
          const date = new Date();
          if (item.lastPoint) {
            const lastDate = new Date(item.lastPoint);
            const diff = Math.abs(lastDate.getTime() - date.getTime());
            if (diff < 600000 + 7200000) {
              item.isLive = true;
            }
          }
          return item;
        });
        this.activeDevice = new Device();
      });
    }
    if (this.selectedEntityType === 'module') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddModule', this.activeModule).subscribe(result => {
        console.log(result);
        this.devices = result;
        this.activeModule = new Module();
      });
    }
    if (this.selectedEntityType === 'keyGroup') {
      return this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/AddKeyGroup', this.activeKeyGroup).subscribe(result => {
        console.log(result);
        this.keyGroups = result;
        this.activeKeyGroup = new GroupKey();
      });
    }
    if (this.selectedEntityType === 'deviceGroup') {
      return this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/AddDeviceGroup', this.activeDeviceGroup).subscribe(result => {
        console.log(result);
        this.deviceGroups = result;
        this.activeDeviceGroup = new GroupDevice();
      });
    }
  }



  updateEntity() {
    if (this.activatedEntity.type === 'key') {
      return this.http.post<Key[]>(this.baseUrl + 'api/Keys/UpdateKey', this.activatedEntity.data).subscribe(result => {
        console.log(result);
        this.keys = result;
        this.toggleActive('key', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'device') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/UpdateDevice', this.activatedEntity.data).subscribe(result => {
        console.log(result);
        this.devices = result;
        this.devices = this.devices.map(function (item) {
          const date = new Date();
          if (item.lastPoint) {
            const lastDate = new Date(item.lastPoint);
            const diff = Math.abs(lastDate.getTime() - date.getTime());
            if (diff < 600000 + 7200000) {
              item.isLive = true;
            }
          }
          return item;
        });
        this.toggleActive('device', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'keyGroup') {
      return this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/UpdateKeyGroup', this.activatedEntity.data).subscribe(result => {
        console.log(result);
        this.keyGroups = result;
        this.toggleActive('keyGroup', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'deviceGroup') {
      return this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/UpdateDeviceGroup', this.activatedEntity.data).subscribe(result => {
        console.log(result);
        this.deviceGroups = result;
        this.toggleActive('deviceGroup', this.activatedEntity.data);
      });
    }
  }

  deleteEntities() {
    const activatedKeys = this.keys.filter(x => x.isSelected === true);
    if (activatedKeys.length > 0) {
      activatedKeys.map(x => x.status = 2);
      this.http.post<Key[]>(this.baseUrl + 'api/Keys/UpdateKeys', activatedKeys).subscribe(result => {
        console.log(result);
        this.keys = result;
        this.toggleActive('key', this.activatedEntity.data);
      });
    }
    const activatedDevices = this.devices.filter(x => x.isSelected === true);
    if (activatedDevices.length > 0) {
      activatedDevices.map(x => x.status = 2);
      this.http.post<Device[]>(this.baseUrl + 'api/Devices/UpdateDevices', activatedDevices).subscribe(result => {
        console.log(result);
        this.devices = result;
        this.toggleActive('device', this.activatedEntity.data);
      });
    }
    const activatedKeyGroups = this.keyGroups.filter(x => x.isSelected === true);
    if (activatedKeyGroups.length > 0) {
      this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/DeleteKeyGroups', activatedKeyGroups).subscribe(result => {
        console.log(result);
        this.keyGroups = result;
        this.activatedEntity = { id: 0, type: '', data: null };
      });
    }
    const activatedDeviceGroups = this.deviceGroups.filter(x => x.isSelected === true);
    if (activatedDeviceGroups.length > 0) {
      this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/DeleteDeviceGroups', activatedDeviceGroups).subscribe(result => {
        console.log(result);
        this.deviceGroups = result;
        this.activatedEntity = { id: 0, type: '', data: null };
      });
    }
  }

  updateGroupLinks(mode, type) {
    let apiURL = 'api/Links/' + mode + type;
    if (mode === 'Remove') {
      apiURL += 'FromGroup';
    } else {
      apiURL += 'ToGroup';
    }

    let data = {};
    if (type === 'Keys') {
      data = {
        groupIDs: this.keyGroups.filter(x => x.isSelected === true).map(item => item.id),
        iDS: this.keys.filter(x => x.isSelected === true).map(item => item.id)
      };
    } else {
      data = {
        groupIDs: this.deviceGroups.filter(x => x.isSelected === true).map(item => item.id),
        iDS: this.devices.filter(x => x.isSelected === true).map(item => item.id)
      };
    }

    return this.http.post<object>(this.baseUrl + apiURL, data).subscribe(result => {
      console.log(result);

    });
  }

  refreshLists() {
    this.http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<GroupKey[]>(this.baseUrl + 'api/GroupKey/GetGroupKey').subscribe(result => {
      this.keyGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/GetGroupDev').subscribe(result => {
      this.deviceGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
  }

  uploadDevices() {
    try {
      const input: HTMLInputElement = document.getElementById('fileInputDevices') as HTMLInputElement;

      const self = this;
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        console.log('CSV: ', text.substring(0, 100) + '...');

        const devicesFromFile = this.csvJSON(text);
        if (this.defaultBootPass && this.defaultBootPass !== '') {
          devicesFromFile.map(function (item) {
            item.BOOTpass = self.defaultBootPass;
          });
        }
        if (this.defaultIpsPass && this.defaultIpsPass !== '') {
          devicesFromFile.map(function (item) {
            item.IPSpass = self.defaultIpsPass;
          });
        }
        if (this.defaultRfAddr && this.defaultRfAddr !== '') {
          devicesFromFile.map(function (item) {
            item.Rfaddr = self.defaultRfAddr;
          });
        }

        return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddDevices', devicesFromFile).subscribe(result => {
          console.log(result);
          this.devices = result;
          this.devices = this.devices.map(function (item) {
            const date = new Date();
            if (item.lastPoint) {
              const lastDate = new Date(item.lastPoint);
              const diff = Math.abs(lastDate.getTime() - date.getTime());
              if (diff < 600000 + 7200000) {
                item.isLive = true;
              }
            }
            return item;
          });
          this.activeDevice = new Device();
          self.openResultModal('success');
        }, function (error) {
          self.openResultModal('error');
        });
      };
      reader.readAsText(input.files[0]);
    } catch (error) {
      // self.openResultModal('error');
    }
  }

  uploadKeys() {
    const self = this;
    try {
      const input: HTMLInputElement = document.getElementById('fileInputKeys') as HTMLInputElement;

      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        console.log('CSV: ', text.substring(0, 100) + '...');
        const keysFromFile = this.csvJSON(text);
        if (this.autoExtNum) {
          keysFromFile.map(function (key) {
            key.ExternalNum = self.hexToDec(key.InternalNum).toString();
          });
        }
        if (this.autoIntNum) {
          keysFromFile.map(function (key) {
            key.InternalNum = self.decToHexPad(key.ExternalNum).toString();
          });
        }
        return this.http.post<Key[]>(this.baseUrl + 'api/Keys/AddKeys', keysFromFile).subscribe(result => {
          console.log(result);
          this.keys = result;
          this.activeKey = new Key();
          self.openResultModal('success');
        }, function (error) {
          self.openResultModal('error');
        });
      };
      reader.readAsText(input.files[0]);
    } catch (error) {
      this.openResultModal('error');
    }
  }

  public csvJSON(csv) {
    const lines = csv.split('\n');

    const result = [];

    const headers = lines[0].split(';');

    for (let i = 1; i < lines.length; i++) {
      if (lines[i] === '') {
        continue;
      }
      const obj = {};
      const currentline = lines[i].split(';');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].replace(/\r?\n?/g, '')] = currentline[j] ? currentline[j].replace(/\r?\n?/g, '') : '';
      }
      result.push(obj);

    }
    return result; // JavaScript object
    // return JSON.stringify(result); //JSON
  }

  openResultModal(type) {
    if (type === 'success') {
      document.getElementById('openEntitySuccessModal').click();
    }
    if (type === 'error') {
      document.getElementById('openEntityErrorModal').click();
    }
  }

}

class GroupKey {
  id: number;
  name: string;
  description: string;
  isActive = false;
  isSelected = false;
}

class GroupDevice {
  id: number;
  name: string;
  description: string;
  isActive = false;
  isSelected = false;
}

class Link {
  keyIds: any[];
  keyGroupIds: number[];
  deviceIds: any[];
  deviceGroupIds: number[];
}

class Module {
  deviceId: number;
  rfaddr: number;
  description: string;
}
