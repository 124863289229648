import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utc'
})
export class UtcPipe implements PipeTransform {

  transform(value: string): any {

    if (!value) {
      return '';
    }

    const dateValue = new Date(value);

    const dateWithNoTimezone = new Date(dateValue.getTime() - 60 * 1000 * dateValue.getTimezoneOffset());

    return dateWithNoTimezone;
  }

}
